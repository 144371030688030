<template>
  <v-btn min-width="0" fab elevation="0" v-bind="attrs" v-on="on" to= "/components/profile/">
    {{ `${this.userProfile.firstName?.substr(0,1)}${this.userProfile.lastName.substr(0, 1)}` }}
  </v-btn>
</template>

<script>

  import { sync } from "vuex-pathify";

  export default {
    name: 'DefaultAccount',
    computed: {
      ...sync("auth", ["userProfile"]),
    },
    data: () => ({
      attrs : null,
      on : null,
      profile: [
        { title: 'Profile' },
        { title: 'Settings' },
        { divider: true },
        { title: 'Log out' },
      ],
    }),
  }
</script>
